/**
 * 全站http配置
 *
 * axios参数说明
 * isSerialize是否开启form表单提交
 * isToken是否需要token
 */
import axios from "axios";
import store from "@/store/";
import router from "@/router/index";
// import {serialize} from '@/util/util';
// import {getToken} from '@/util/auth';
import NProgress from "nprogress";
import { Message } from "element-ui"

// import {Loading } from 'element-ui';
import _ from "lodash";

const service = axios.create({
  baseURL: "",
  withCredentials: true,
  timeout: 60000,
});
//loading对象
// let loading;

//默认超时时间
axios.defaults.timeout = 60000;
//返回其他状态码
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500;
};
//跨域请求，允许保存cookie
axios.defaults.withCredentials = true;
// NProgress 配置
NProgress.configure({
  showSpinner: false,
});
//http request拦截
axios.interceptors.request.use(
  (config) => {
    // loading = Loading.service({
    // lock: true,
    // text: "数据加载中",
    // background: 'rgba(255, 255, 255, 0.5)',
    // target:  "body"
    // });

    //开启 progress bar
    NProgress.start();
    const meta = config.meta || {};
    const isToken = meta.isToken === false;
    // config.headers['Authorization'] = `Basic ${Base64.encode(`${website.clientId}:${website.clientSecret}`)}`;
    config.responseEncoding = "utf8";
    //让每个请求携带token
    // if (getToken() && !isToken) {
    //   config.headers[website.tokenHeader] = getToken()
    // }
    //headers中配置text请求
    if (config.text === true) {
      config.headers["Content-Type"] = "text/plain";
    }
    //headers中配置serialize为true开启序列化
    if (config.method === "post" && meta.isSerialize === true) {
      config.data = serialize(config.data);
    }

    return config;
  },
  (error) => {
    // loading.close();
    return Promise.reject(error);
  }
);
//http response 拦截
axios.interceptors.response.use(
  (res) => {
    const {
      request:{
        responseURL
      },
      data
    } = res;

    // loading.close();
    //关闭 progress bar
    NProgress.done();
    //获取状态码
    const status = data.code || res.status;
    // const statusWhiteList = website.statusWhiteList || [];
    const message = data.msg || data.error_description || "未知错误";
    //如果在白名单里则自行catch逻辑处理
    // if (statusWhiteList.includes(status)) return Promise.reject(res);
    //如果是401则跳转到登录页面
    if (status === 401)
      store.dispatch("FedLogOut").then(() =>
        router.push({
          path: "/login",
        })
      );
    // 处理 300 且返回内容为 token无效的情况
    if (status === 300) {
      store.dispatch("FedLogOut").then(() =>
        router.push({
          path: "/login",
        })
      );
    }

    // 未登录
    if (data.state === "0x0008") {
      window.localStorage.clear();
    }
    // 未绑定账户
    if (data.state === "0x0021") {
      if (responseURL.indexOf("/mgr/app/saveRecentUsedApp") >-1) return;
      Message({
        message: data.message,
        type: "warning",
        duration: .5* 1000,
        onClose: () => {
          router.push({
            path: "/login/relevance",
            query: { redirect: router.currentRoute.fullPath }
          })
        }
      });
      return {
        data:{state: '0x0000'}
      }
    }
    return res;
  },
  (error) => {
    // loading.close();
    return Promise.reject(error);
  }
);

export default axios;
