/*
 * @Description: 用户信息
 * @Author: LZ
 * @Date: 2023-11-02 14:44:02
 */
import { getUserInfo } from "@/api/aidata";

const state = {
  /**
   *  unionId
   */
  unionId: "",
  userInfo: {},
};

const mutations = {
  SET_UNION_ID: (state, unionId) => {
    state.unionId = unionId;
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
};

const actions = {
  getUserInfo: ({ commit }, val) => {
    return new Promise((resolve,reject)=>{
      getUserInfo({
        token: localStorage.getItem("tokenKey"),
      }).then((res) => {
        if (res.data.state === "0x0000"){
          commit("SET_UNION_ID", res.data.data.unionId);
          commit("SET_USER_INFO", res.data.data);
          resolve(res.data);
        }else {
          reject(res.data.message);
        }
      }).catch(e => {
        reject(e);
      });
    })
  },
};

export default {
  namespaced: true, // 用于解决不同模块的命名冲突问题
  state,
  mutations,
  actions,
};
