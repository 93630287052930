/*
 * @Description: 产品列表
 * @Author: LZ
 * @Date: 2023-11-02 15:03:49
 */
const state = {
  catogrLists: [
    {
      aa: "选项1",
    },
    {
      aa: "选项2",
    },
    {
      aa: "选项3",
    },
  ],
  catogrList: [
    {
      bb: "选项1",
    },
    {
      bb: "选项2",
    },
    {
      bb: "选项3",
    },
  ],
  navList: [], // 导航栏列表
  logStatus: false, // 登录状态 默认未登录
};

const mutations = {
  UPDATE_NAV_LIST(state, data) {
    state.navList = data;
  },
  UPDATE_LOG_STATUS(state, data) {
    state.logStatus = data;
  },
};

export default {
  namespaced: true, // 用于解决不同模块的命名冲突问题
  state,
  mutations,
};
