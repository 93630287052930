/*
 * @Description: getters 汇总
 * @Author: LZ
 * @Date: 2023-11-02 14:37:59
 */
const getters = {
  unionId: (state) => state["user-info"].unionId,
  navList: (state) => state["menu-list"].navList,
  logStatus: (state) => state["menu-list"].logStatus,
  userInfo: (state) => state["user-info"].userInfo,
};

export default getters;
