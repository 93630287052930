<template>
  <div id="app">
    <!-- <router-view v-if="isRouterAlive"></router-view> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {
    // Home
  },
  provide() {
    // 注册一个方法
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style>
body {
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 0 !important;
}
#app {
  overflow-x: hidden;
}
</style>
