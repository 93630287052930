/*
 * @Description: cookie 使用封装
 * @Date: 2023-11-07 16:14:23
 * @LastEditTime: 2024-03-19 15:42:10
 */
//获取cookie
export let getCookie = (name) => {
  let arr,
    reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
  arr = document.cookie.match(reg);
  if (arr) {
    return decodeURIComponent(arr[2]);
  } else {
    return null;
  }
};
//设置cookie
export let setCookie = (name, vaule, time) => {
  let d = new Date();
  d.setTime(d.getTime() + time * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie =
    name + '=' + vaule + ';' + 'domain=.aishipgo.com;path=/;' + expires;
};

//删除cookie
export function delCookie(name) {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = getCookie(name);
  if (cval != null)
    document.cookie = name + '=' + cval + ';expires=' + exp.toGMTString();
}
