import './plugins/element.js';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/element-theme-custom.less';
import './api/flexible.js'; // 移动端适配
import 'viewerjs/dist/viewer.css';
import '@/assets/iconfont/iconfont.css';

import { delCookie, getCookie, setCookie } from '@/util/cookie';

import $ from 'jquery';
import App from './App.vue';
import ElementUI from 'element-ui';
import Highlight from './util/highlight';
import { MessageBox } from 'element-ui';
import Viewer from 'v-viewer';
import Vue from 'vue';
import VueAnimateNumber from 'vue-animate-number';
import VueCookies from 'vue-cookies';
import VueI18n from 'vue-i18n';
import axios from 'axios';
import method from './api/public.js';
import moment from 'moment';
import qs from 'qs';
import router from './router';
import store from './store';
import updateElementUiTheme from 'update-element-ui-theme';

// 全局引用 公共方法

Vue.prototype.method = method;

// 数字动效

Vue.use(VueAnimateNumber);

// 图片查看

// 处理代码块

Vue.use(Highlight);
// import hljs from 'highlight.js';
// import './assets/css/highlight.css' // 代码高亮风格，选择更多风格需导入
// //创建v-highlight全局指令
// Vue.directive('highlight',function (el) {
//   let blocks = el.querySelectorAll('pre code');
//   blocks.forEach((block)=>{
//     hljs.highlightBlock(block)
//   })
// })

// 改变elementui主题色

updateElementUiTheme({
  theme: '#777AED', // 要修改的主题色
  // themeName: "--my-theme", // 要同步更新的 css 变量名称，这个可以不传
});
Vue.use(Viewer);
Viewer.setDefaults({
  Options: {
    inline: true,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: 'data-source',
  },
});

//跨域请求，允许保存cookie
axios.defaults.withCredentials = true;

Vue.use(VueCookies); // 挂在在全局

window.qs = qs;

window.jQuery = $;
window.$ = $;

// import VueAxios from "vue-axios";

Vue.prototype.$axios = axios;

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'zh',
  messages: {
    en: require('./lang/en'),
    zh: require('./lang/zh'),
  },
});

Vue.filter('dataFMT', (input, formatString = 'YYYY-MM-DD') => {
  //es5函数参数设置默认值
  //const lastFormatString = formatString ||
  // moment(input) 把时间字符串转成时间对象
  // format(formatString) 把时间对象，按照指定格式，格式化成符合条件的字符串
  return moment(input).format(formatString);
});

// Vue.use(VueAxios, axios)
Vue.config.productionTip = false;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.use(ElementUI);
Vue.prototype.$cookieStore = {
  getCookie,
  setCookie,
  delCookie,
};
// 时间戳转换
Vue.prototype.dateTypeFormat = function (fmt, date) {
  let ret;
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (const k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0')
      );
    }
  }
  return fmt;
};

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
