import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
// 首页
const home = () => import("../views/secondPage/newHome.vue");
const newHome = () => import("../views/home/home.vue");

// 登录
const logIn = () => import("../views/logIn/index.vue");
const wechat = () => import("../views/logIn/wechat.vue");
const password = () => import("../views/logIn/password.vue");
const register = () => import("../views/logIn/register.vue");
const registerAccount = () => import("../views/logIn/registerAccount.vue");
const forget = () => import("../views/logIn/forget.vue");
const relevance = () => import("../views/logIn/relevance.vue"); //
const dhRelevance = () => import("../views/logIn/dhRelevance.vue"); //

// 测试页面
const ceshi = () => import("../views/home/ceshi.vue");

// 图片类
const imgMark = () => import("../views/secondPage/imgWatermark.vue"); // 图片水印
const imgWhite = () => import("../views/secondPage/imgWhiteBg.vue"); // 图片抠图
const imgYellow = () => import("../views/secondPage/imgYellow.vue"); // 图片色情
const logoBrand = () => import("../views/secondPage/logoBrand.vue"); // 品牌logo
const imgSame = () => import("../views/secondPage/imgSimilar.vue"); // 图片相似
const brandIden = () => import("../views/secondPage/brandIdentify.vue"); // 通用品牌识别
const logoSmear = () => import("../views/secondPage/logoSmear.vue"); // logo涂抹

// 文本类
const title = () => import("../views/secondPage/title.vue"); // 标题优化
const titleAnaly = () => import("../views/secondPage/titleAnalysis.vue"); // 标题解析及keyword挖掘
const textInfri = () => import("../views/secondPage/textInfringement.vue"); // 文本侵权
const textSearchs = () => import("../views/secondPage/textSearch.vue"); // 文本搜索
// chatgpt 更名为 Feitian
const chatgpts = () => import("../views/secondPage/chatgpt.vue");
const aboutUs = () => import("../views/secondPage/aboutUs.vue");
const domainQuiz = () => import("../views/secondPage/domainQuiz.vue"); // 领域知识问答

// 视频合成
const imgVideo = () => import("../views/secondPage/imgToVideo.vue"); // 视频合成
// 解决方案
const solution = () => import("../views/secondPage/solution.vue"); // 解决方案
// 留言板
const messageBoard = () => import("../views/secondPage/messageBoard.vue"); //留言板

// code 后台管理界面
const addCode = () => import("../views/code/addCode.vue"); // 添加界面
const listCode = () => import("../views/code/listCode.vue"); // 列表界面
const resultCode = () => import("../views/code/resultCode.vue"); // 结果界面

// 文档
const apiDocument = () => import("../views/document/apiDocument.vue"); //

// 暂时不处理界面
const chooseImg = () => import("../views/secondPage/chooseImg.vue"); // 最佳选图
const ocr = () => import("../views/secondPage/ocr.vue"); // ocr

// 1024
const sellingPoint = () => import("../views/secondPage/sellingPoint.vue"); // 启航大模型
const changing = () => import("../views/secondPage/changing.vue"); // 换衣
const smearCruch = () => import("../views/secondPage/smearCruch.vue"); // 消消乐

// AI 2D视频合成
const aiVideo = () => import("../views/secondPage/aiVideo.vue"); // 2D视频合成

// 智能 IM 介绍页
const imIntroduce = () => import("../views/secondPage/intelligent-im.vue"); // 智能 IM 介绍页

// TT 带货倾向分析
const ttLoadingTendencyAnalysis = () =>
  import("../views/secondPage/tt-loading-tendency-analysis.vue"); // TT 带货倾向分析

// 商品 SPU 转 SKU
const commoditySPUToSKU = () =>
  import("../views/secondPage/commodity-spu-to-sku.vue");

// 商品 SPU 转 SKU
const ProtocolPage = () => import("../views/secondPage/protocol.vue");

const routes = [
  {
    path: "/",
    component: newHome,
  },
  {
    path: "/home",
    name: "home",
    component: home,
  },
  {
    path: "/ceshi",
    name: "ceshi",
    component: ceshi,
  },
  {
    path: "/newHome",
    name: "newHome",
    component: newHome,
  },

  {
    path: "/logIn", //登录页
    name: "logIn",
    component: password,
    redirect: "/logIn/register",
    children: [
      {
        path: "/logIn/register",
        name: "register",
        component: register,
      },
      {
        path: "/logIn/registerAccount",
        name: "registerAccount",
        component: registerAccount,
      },
      {
        path: "/logIn/forget",
        name: "forget",
        component: forget,
      },
      {
        path: "/logIn/relevance",
        name: "relevance",
        component: relevance,
      },
      {
        path: "/logIn/dhRelevance",
        name: "dhRelevance",
        component: dhRelevance,
      },
    ],
  },
  {
    path: "/wechat", //微信登录
    name: "logInWechat",
    component: wechat,
  },
  // {
  //   path: "/password", //微信登录
  //   name: "logInPassword",
  //   component: password,
  // },

  // 重新新建的页面
  {
    path: "/brandIden",
    name: "brandIden",
    component: brandIden,
  },
  {
    path: "/Feitian",
    name: "feitian",
    component: chatgpts,
  },
  {
    path: "/imgSame",
    name: "imgSame",
    component: imgSame,
  },
  {
    path: "/imgVideo",
    name: "imgVideo",
    component: imgVideo,
  },
  {
    path: "/imgMark",
    name: "imgMark",
    component: imgMark,
  },
  {
    path: "/imgWhite",
    name: "imgWhite",
    component: imgWhite,
  },
  {
    path: "/imgYellow",
    name: "imgYellow",
    component: imgYellow,
  },
  {
    path: "/logoBrand",
    name: "logoBrand",
    component: logoBrand,
  },
  {
    path: "/textInfri",
    name: "textInfri",
    component: textInfri,
  },
  {
    path: "/textSearchs",
    name: "textSearchs",
    component: textSearchs,
  },
  {
    path: "/title",
    name: "title",
    component: title,
  },
  {
    path: "/titleAnaly",
    name: "titleAnaly",
    component: titleAnaly,
  },
  {
    path: "/logoSmear",
    name: "logoSmear",
    component: logoSmear,
  },
  {
    path: "/domainQuiz",
    name: "domainQuiz",
    component: domainQuiz,
  },
  {
    path: "/chooseImg",
    name: "chooseImg",
    component: chooseImg,
  },
  {
    path: "/ocr",
    name: "ocr",
    component: ocr,
  },
  {
    path: "/solution",
    name: "solution",
    component: solution,
  },
  {
    path: "/messageBoard",
    name: "messageBoard",
    component: messageBoard,
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: aboutUs,
  },
  {
    path: "/addCode",
    name: "addCode",
    component: addCode,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/apiDocument",
    name: "apiDocument",
    component: apiDocument,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/listCode",
    name: "listCode",
    component: listCode,
  },
  {
    path: "/resultCode",
    name: "resultCode",
    component: resultCode,
  },
  {
    path: "/aiVideo",
    name: "aiVideo",
    component: aiVideo,
  },
  {
    path: "/sellingPoint",
    name: "sellingPoint",
    component: sellingPoint,
  },
  {
    path: "/changing",
    name: "changing",
    component: changing,
  },
  {
    path: "/smearCruch",
    name: "smearCruch",
    component: smearCruch,
  },
  {
    path: "/intelligentIM",
    name: "intelligentIM",
    component: imIntroduce,
  },
  {
    path: "/tt-loading-tendency-analysis",
    name: "ttLoadingTendencyAnalysis",
    component: ttLoadingTendencyAnalysis,
  },
  {
    path: "/commodity-spu-to-sku",
    name: "commoditySPUToSKU",
    component: commoditySPUToSKU,
  },
  {
    path: "/protocol",
    name: "ProtocolPage",
    component: ProtocolPage,
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  mode: "hash",
  scrollBehavior: () => ({ y: 0 }), //滚动到顶端
});

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};
// 1024相关页面无权限禁止进入
const auth1024NameList = [
  // 'apiDocument',
  // 'sellingPoint',
  // 'smearCruch',
  // 'changing',
  // 'aiVideo',
];
router.beforeEach((to, from, next) => {
  const auth1024 = window.sessionStorage.getItem("isIntranet");
  const { name, path } = to;
  console.log("to", to, path.query?.redirect);
  if (
    auth1024NameList.findIndex((item) => item == name) > -1 &&
    auth1024 != 1
  ) {
    console.log(11111);
    next("/");
  } else if (path.indexOf("/logIn") > -1 && !to.query?.redirect) {
    console.log(2222);
    // 当前页面为login相关页面 redirect默认为
    if (from.path.toLowerCase().indexOf("login") > -1) {
      console.log(3333);
      to.query.redirect = window.location.origin;
    } else {
      console.log(4444);
      to.query.redirect = to.query.redirect || window.location.href;
    }
    next({
      path: to.path,
      query: to.query,
    });
  } else {
    console.log(55555);
    next();
  }
});

export default router;
