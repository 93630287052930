export default {
    // 图片拖拽效果
    dragDropImages(){
       
        const slider = document.getElementById("slider");
        let sliderPos;
        slider.addEventListener("input", function(e) {
            sliderPos = e.target.value;
            document.querySelector(".foreground-img").style.width = `${sliderPos}%`;
            document.querySelector(".afterOptimization").style.left = `calc(${sliderPos}%)`;
            if(sliderPos<50){
                    if(window.matchMedia("(max-width: 990px)").matches){        
                        // console.log("这是一个移动设备。"); 
                        document.querySelector(
                        ".trigger-gesture"
                        ).style.left = `calc(${sliderPos}% - 15px)`;
                    }else{      
                        // console.log("这是平板电脑或台式电脑。"); 
                        document.querySelector(
                        ".trigger-gesture"
                        ).style.left = `calc(${sliderPos}% - 25px)`;
                    }
                   
            }else{
                    if(window.matchMedia("(max-width: 990px)").matches){        
                        // console.log("这是一个移动设备。"); 
                        document.querySelector(
                        ".trigger-gesture"
                        ).style.left = `calc(${sliderPos}% - 12px)`;
                    }else{      
                        // console.log("这是平板电脑或台式电脑。"); 
                        document.querySelector(
                        ".trigger-gesture"
                        ).style.left = `calc(${sliderPos}% - 20px)`;
                    }  
            }
            
        });
    }
}