import request from "@/router/axios";
// 导航菜单栏接口数据
export const getTreeList = (data) => {
  return request({
    // url: "/api/server/sysMenu/list",
    url: "/api/sysMenu/list",
    method: "post",
    data: {
      ...data,
    },
  });
};
// 查询菜单树-new
export const getMenuTree = (data) => {
  // 获取token，假设存储在localStorage中
  const token = localStorage.getItem("tokenKey");
  if (!!token) {
    return request({
      url: "/api/menu/menuTree",
      method: "post",
      headers: {
        Token: `${token}`, // 设置Authorization头部
        "Content-Type": "application/json", // 设置Content-Type头部
      },
      data: {
        ...data,
      },
    });
  }
  return request({
    url: "/api/menu/menuTree",
    method: "post",
    data: {
      ...data,
    },
  });
};
// 获取留言板数据
export const getMessageList = (params) => {
  return request({
    url: "/api/message/find",
    method: "get",
    headers: {
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
    },
    params: {
      ...params,
    },
  });
};

// 留言
export const messageToDhgate = (data) => {
  return request({
    url: "/api/message/add",
    method: "post",
    headers: {
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
    },
    data: {
      ...data,
    },
  });
};

//保存最近使用服务
export const saveRecentUsedApp = (data) => {
  return request({
    url: "/mgr/app/saveRecentUsedApp",
    method: "post",
    headers: {
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
    },
    data: {
      ...data,
    },
  });
};

// 获取用户信息
export const getUserInfo = (data) => {
  return request({
    url: "/api/user/getUserInfo",
    method: "post",
    headers: {
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
    },
    data: {
      ...data,
    },
  });
};

// chartGPT应用发送查询
// export const getTxtList = (data) => {
//   return request({
//     url: "/api/chat",
//     method: "post",
//     headers:{
//       token:localStorage.getItem("tokenKey")?localStorage.getItem("tokenKey"):''
//     },
//     data: {
//       ...data,
//     },
//   });
// };
export const getTxtList = (data) => {
  return request({
    url: "https://chatgpt-api.dhgate.com/api/v3/chat/completions",
    method: "post",
    headers: {
      Authorization: "72a8b3b8c23f46fbaee1e48b2be4f807",
    },
    data: {
      ...data,
    },
  });
};

// 批量扣白底图
export const uploadMore = (data) => {
  return request({
    url: "/api/img/batchWhiteBase",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      ...data,
    },
  });
};

// 下载白底图
export const upoloadWhiteBase = (params) => {
  return request({
    url: "/api/img/downloadWhiteBase",
    method: "get",
    params: {
      ...params,
    },
    responseType: "blob", //必须加，否则可能出现乱码或者文件错误，导致文件无法打开
  });
};

// 下载白底图模板
export const uoloadWhiteForm = (params) => {
  return request({
    url: "/api/img/downloadWhiteBaseFormwork",
    method: "get",
    params: {
      ...params,
    },
  });
};

// 导出批量白底图
export const downLoadBase = (params) => {
  return request({
    url: "/api/img/exportWhiteBase",
    method: "get",
    params: {
      ...params,
    },
  });
};

// 扣白底图
export const batchWhiteBase = (data) => {
  return request({
    url: "/api/img/whiteBase",
    method: "post",
    // headers:{
    //     'Content-Type': 'multipart/form-data'
    // },
    data: {
      ...data,
    },
  });
};

// chat页签初始化
export const getChatLabel = (params) => {
  return request({
    url: "/api/tab/init",
    method: "get",
    params: {
      ...params,
    },
  });
};

// chat-GPT批量处理
export const chatMoreGpt = (data) => {
  return request({
    url: "/api/chatBatch",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      ...data,
    },
  });
};

// 文本侵权、违禁词检测
export const getOffending = (params) => {
  // return request.get("/api/text/titlecompliance?discription="+params)
  return request({
    url: "/api/text/titlecompliance",
    method: "get",
    headers: {
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
    },
    params: {
      ...params,
    },
  });
};

// 商品标题解析+keyword挖掘
export const getKeyword = (data) => {
  return request({
    url: "/api/text/wordExtract",
    method: "post",
    // header请求
    headers: {
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
    },
    data: {
      ...data,
    },
  });
};
export const getvectortext = (data) => {
  return request({
    url: "/api/vector/textSimilaritySearch",
    method: "post",
    // header请求
    headers: {
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
    },
    data: {
      ...data,
    },
  });
};

// 微信登录获取url接口
export const weiXinLog = (params) => {
  return request({
    url: "/api/v1/wechat/login_url",
    method: "get",
    params: {
      ...params,
    },
  });
};

// 获取用户基本信息
export const weiXinuser = (params) => {
  return request({
    url: "/api/v1/wechat/getUserInfoToken",
    method: "get",
    params: {
      ...params,
    },
  });
};

// 发送验证码
export const send = (data) => {
  return request({
    url: "/api/phoneCode/send",
    method: "post",
    data: {
      ...data,
    },
  });
};

// 验证码准确性认证
export const phoneCodeVerify = (data) => {
  return request({
    url: "/api/phoneCode/verify",
    method: "post",
    data: {
      ...data,
    },
  });
};

// 绑定手机号登录
export const wechatLogin = (data) => {
  return request({
    url: "/api/v1/wechat/login",
    method: "post",
    data: {
      ...data,
    },
  });
};
// 据用户手机号获取有效token
export const phoneToken = (data) => {
  return request({
    url: "/api/v1/wechat/tokens",
    method: "post",
    data: {
      ...data,
    },
  });
};

// 水印涂抹选中高量调用接口
export const watermarkInpaint = (data) => {
  return request({
    url: "/api/img/watermarkInpaint",
    // url: "/api/img/multiClearWatermark",

    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
      isSample: data.isSample,
    },
    data: {
      ...data,
    },
  });
};

// 白底图 选中高量调用接口
export const whiteBackground = (data) => {
  return request({
    url: "/api/img/whiteBase",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
      isSample: data.isSample,
    },
    data: {
      ...data,
    },
  });
};

// 图相似 选中高量调用接口
export const figureSimilarity = (data) => {
  return request({
    url: "/api/vector/imgSimilaritySearch",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
      isSample: data.isSample,
    },
    data: {
      ...data,
    },
  });
};

// 色情识别   选中高亮调用接口
export const multiSensitive = (data) => {
  return request({
    url: "/api/img/multiSensitive",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
      isSample: data.isSample,
    },
    data: {
      ...data,
    },
  });
};

// 品牌识别    选中高亮调用接口
export const multiLogoRecognition = (data) => {
  return request({
    url: "/api/img/multiLogoRecognition",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
      isSample: data.isSample,
    },
    data: {
      ...data,
    },
  });
};

// logo涂抹
export const logoSmear = (data) => {
  return request({
    url: "/api/img/inpaint",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
      isSample: data.isSample,
    },
    data: {
      ...data,
    },
  });
};

// 知识领域问答
export const documentQa = (data) => {
  return request({
    url: "/api/text/plat/documentQa",
    method: "post",
    headers: {
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
      isSample: data.isSample,
    },
    data: {
      ...data,
    },
  });
};

// 查询获取文档信息
export const getDocument = (params) => {
  return request({
    url: "/api/text/plat/getDocument",
    method: "get",
    headers: {
      isSample: params.isSample,
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
    },
    params: {
      ...params,
    },
  });
};

// 通用logo识别    选中高亮调用接口
export const infringement = (data) => {
  return request({
    url: "/api/img/commonLogo",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
      isSample: data.isSample,
    },
    data: {
      ...data,
    },
  });
};

// 主动营销
export const activeMarketing = (data) => {
  return request({
    url: "/activeMarketing",
    method: "post",

    data: {
      ...data,
    },
  });
};

// 业务方列表接口
export const bizUnitList = (data) => {
  return request({
    url: "/mgr/biz/bizUnitList",
    method: "post",
    headers: {
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
    },
    data: {
      ...data,
    },
  });
};

// Ai 业务创建
export const bizCreate = (data) => {
  // console.log(data)
  return request({
    url: "/mgr/biz/bizCreate",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
    },
    data: {
      ...data,
    },
  });
};

// Ai 模糊搜索
export const bizPage = (data) => {
  // console.log(data)
  return request({
    url: "/mgr/biz/bizPage",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
    },
    data: {
      ...data,
    },
  });
};

// Ai 修改状态
export const bizUpdate = (data) => {
  // console.log(data)
  return request({
    url: "/mgr/biz/bizUpdate",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
    },
    data: {
      ...data,
    },
  });
};
